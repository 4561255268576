<template>
    <div class="p-2">
        <div class="h-100 flex-fill d-flex flex-column">
            <h3 class="text-center font-weight-bolder text-white mt-2">Store Registration</h3>

            <hr style="border-color: #FFAD32;" class="w-100 " />

            <h3 class="text-white font-weight-bolder mt-3 h4">Terms & Condition</h3>

            <p class="text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vehicula porttitor magna a porttitor. Fusce
                nibh lectus, faucibus ut imperdiet nec, Nullam a libero vitae lorem varius suscipit ac a mi. Donec feugiat
                velit eu justo suscipit cursus. In quis lectus vel turpis consectetur interdum. Curabitur a dui in nisi
                malesuada fermentum. Suspendisse mattis libero eget risus vulputate, ac tincidunt ipsum congue. Nunc eget
                dui dapibus magna vehicula finibus id vel arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Aenean posuere massa odio, sit amet tristique massa interdum sed. Donec rhoncus est quis nisi pellentesque,
            </p>

            <form id="frm">
                <div class="mt-3">
                    <label class="h3 text-white">Store Name</label>
                    <div class="input-group">
                        <input class="form-control" name="inp[store_name]" placeholder="Store Name" v-model="input_data.store_name">
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Location</label>
                    <div class="input-group">
                        <input class="form-control" name="inp[store_location]" placeholder="Location" v-model="input_data.store_location">
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Phone</label>
                    <div class="input-group">
                        <input class="form-control" name="inp[store_phone]" placeholder="Phone" v-model="input_data.store_phone">
                    </div>
                </div>
                
                <div class="mt-2">
                    <label class="h3 text-white">Description</label>
                    <div class="input-group">
                        <textarea class="form-control" name="inp[store_details]" placeholder="Description" v-model="input_data.store_details"></textarea>
                    </div>
                </div>

                <div class="mt-3">
                    <label class="h3 text-white">Picture</label>
                    <div class="input-group">
                        <input class="form-control" type="file" accept="image/*" name="store_picture" placeholder="Phone">
                    </div>
                </div>
            </form>

            <div class="btn mt-1"
                style="padding:.75em 3em;margin-top:.5em;gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 15px;">
                <div class="h4 text-white mb-0" @click="onSubmit()">Im Ready!</div>
            </div>

        </div>
    </div>
</template>

<script>

import store from '@/store'
import Cookies from 'universal-cookie';
let cookies = new Cookies()

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        store_data(){
            return this.user?.store_data
        },
        
    },
    mounted(){
        
    },
    watch: {
        store_data: {
            handler: function(val){
                if(val){
                    this.input_data = val
                }
            }, immediate: true, deep: true
        }
    },
    methods: {
        onSubmit() {
            let payload = new FormData($('#frm')[0])
            payload.append('inp[user_id]', cookies.get('token'))
            if(this.store_data?.store_id){
                payload.append('id', this.store_data?.store_id)
            }

            store.dispatch('store_api/SetupStore', payload).then(() => {
                store.dispatch('auth/GetUserData')
            })
        }
    }, 
    data() {
        return {
            input_data: {}
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }

    input:focus {
        color: white !important;
    }

    .input-group span {
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        font-size: 24px;
        color: #1F9BCD;
        background-color: transparent;
    }
    .input-group input, .input-group textarea {
        margin-left: 0;
        padding-left: 0;
        border: unset;
        border-radius: unset;
        border-bottom: 1px solid white;
        height: 50px;
        color: white;
        background-color: transparent !important;
    } 
</style>